import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { authorizeConfirmApproval } from 'scripts/authorizeConfirmApproval'

interface Props {
  lockedEventId: string | undefined
  lockedDeviceUuid: string | undefined
  isSaml: string | null | undefined
  appClientKey?: string | null
}

export const Main: FC<Props> = ({
  isSaml,
  lockedDeviceUuid,
  lockedEventId,
  appClientKey,
}) => {
  const [openSnackbar] = useSnackbar()
  const { t } = useTranslation()
  const handleClick = async () => {
    const params = {
      locked_event_id: lockedEventId,
      locked_device_uuid: lockedDeviceUuid,
      is_saml: isSaml === 'true',
    }
    const { result } = await authorizeConfirmApproval({
      params: { ...params, totp_answered: 'verified' },
      appClientKey: appClientKey,
    })
    if (result === 'error') {
      openSnackbar(
        '本人確認が完了していません。２段階認証を完了してください',
        'identityVerificationNotCompleted',
      )
    }
  }
  return (
    <>
      <p className="text-xl px-8 text-center">
        {t('ご本人確認ありがとうございました')}
      </p>
      <div className="w-full px-6 text-gray-700">
        <p className="text-lg pt-10">{t('数秒後にマイページに移動します。')}</p>
        <p className="text-sm mx-16 mt-10 mb-12">
          {t('二段階認証の完了後、自動でログインできない場合は')}
          <button
            data-test="submit"
            onClick={handleClick}
            className="locked-help-link"
          >
            {t('こちら')}
          </button>
        </p>
        <div className="mb-6 flex justify-center">
          <a
            className="locked-help-link"
            href="https://www.notion.so/de9fb681780c46588b0aec0c60057e21"
            target="_blank"
          >
            {t('よくある質問')}
          </a>
        </div>
      </div>
    </>
  )
}
